import './App.css';
import Tiles from './components/tiles.js';
import Header from './components/header.js';


// TODO: tidy up and document
// TODO: add some sort of analytics

function App() {
  return (
    <div className="container">
      <Header />
      <Tiles />
    </div>
  );
}

export default App;
