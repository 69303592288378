import React, { Component } from 'react';
import { format } from 'date-fns'
import configData from '../config.json';

class App extends Component {
    constructor(props) {
        super(props);
    
        this.state = {
            records: 0,
            results: [],
            lastRecordDatetime: format(new Date(), "yyyy-MM-dd'T'HH:mm:ssxxx"),
            isLoading: false,
            error: null
        }
    };

    callback = (entries) => {
        entries.forEach(entry => {
            if (entry.isIntersecting){
                this.fetchData(this.state.lastRecordDatetime);
            }
        });
      };

    componentDidMount() {
        // when #infinite-scroll-trigger comes into view, load more results
        // see bug explained in README.md
        const options = {threshold: 1.0};
        const observer = new IntersectionObserver(this.callback, options);
        observer.observe(document.querySelector('#infinite-scroll-trigger'));
    }

    fetchData = (fromDatetime) => {
        this.setState({ isLoading: true });
        let input_data = { group: configData.api_identifier, numRecords: 15, fromDatetime: fromDatetime};
        // make request to `publishing-links-api-public`
        fetch(configData.api_url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(input_data),
        })
        .then(response => response.json())
        .then(data => {
            if (data.results && data.results.length) {
                // if we have results, then load them into state
                // we append so that we retain previous records loaded
                this.setState({ records: (data.records + this.state.records) });
                this.setState({ results: [...this.state.results, ...data.results] });
                this.setState({ lastRecordDatetime: data.results[data.results.length - 1].publishedDatetime });
            }
            this.setState({ isLoading: false });
        })
        .catch((error) => {
            console.error('Error:', error);
        });
    } 

    render() {
        const { results, isLoading } = this.state;

        const data = (results).map((result) =>
            <a href={result.destinationURL} key={result.publishedDatetime}>
                <div className="cell">
                    <img src={result.thumbnail256x256} alt={result.description}/>
                </div>
            </a>
        );

        if (isLoading){
            // loading, so return results (previously loaded) with the loading wheel animation
            return (
                <div>
                    <div className="grid" id="grid">
                        {data}
                    </div>
                    <div id="infinite-scroll-trigger"></div>
                    <div className="loadingWheel">
                        <div className="loader"></div>
                    </div>
                </div>
            )
        } else {
            // not loading - return without loading wheel animation
            return (
                <div>
                    <div className="grid" id="grid">
                        {data}
                    </div>
                    <div id="infinite-scroll-trigger"></div>
                </div>
            )
        }
    };
};

export default App;
