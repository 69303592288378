import React from 'react';

import configData from '../config.json';
import logo from '../assets/logo-256x256.png';

function Header() {
    function handleSiteClick(destination){
        window.location.href = destination;
    }
    
    return (
        <header>
            <img src={logo} alt={configData.readable_name} />
            <button onClick={() => handleSiteClick(configData.website_url)}>Visit Our Website</button>
            <button onClick={() => handleSiteClick("mailto:" + configData.email_address)}>Contact Us</button>
        </header>
    );
};

export default Header;
